import React from "react";

import type { Column, ColumnOrderState, Header, Table } from "@tanstack/react-table";
import { flexRender } from "@tanstack/react-table";
import { GripVertical, SortAsc, SortDesc } from "lucide-react";
import { useDrag, useDrop } from "react-dnd";
import tw from "twin.macro";

import type { DataTableColumnDef } from "@/app/core/datatable/datatable.types";
import { Absolute } from "@/app/core/ui/components/Absolute";
import { Box } from "@/app/core/ui/components/Box";

const reorderColumn = (
  draggedColumnId: string,
  targetColumnId: string,
  columnOrder: string[]
): ColumnOrderState => {
  columnOrder.splice(
    columnOrder.indexOf(targetColumnId),
    0,
    columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0] as string
  );
  return [...columnOrder];
};

interface ColumnHeaderProps<D> {
  header: Header<D, unknown>;
  table: Table<D>;
  disableInteractions: boolean;
}

// eslint-disable-next-line comma-spacing
export const ColumnHeader = <D,>({
  header,
  table,
  disableInteractions,
}: ColumnHeaderProps<D>) => {
  const { getState, setColumnOrder } = table;
  const { columnOrder } = getState();
  const { column } = header;

  const [{ canDrop, isOver, isAfter }, dropRef] = useDrop({
    canDrop: () => !disableInteractions,
    accept: "column",
    drop: (draggedColumn: Column<D>) => {
      setColumnOrder(reorderColumn(draggedColumn.id, column.id, columnOrder));
    },
    collect: (monitor) => {
      const initialX = monitor.getInitialClientOffset()?.x;
      const currentX = monitor.getClientOffset()?.x;
      let deltaX: number | undefined = undefined;
      if (initialX !== undefined && currentX !== undefined) {
        deltaX = currentX - initialX;
      }

      return {
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver(),
        isAfter: deltaX ? deltaX > 0 : undefined,
      };
    },
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    canDrag: () => !disableInteractions,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => column,
    type: "column",
  });

  return (
    <th
      ref={disableInteractions ? undefined : dropRef}
      className="group"
      tw="relative text-gray-600 font-medium py-1 px-2 4xl:px-3 text-left text-md overflow-hidden"
      css={{
        ...(header.column.getCanSort() ? tw`hover:bg-gray-150 cursor-pointer` : {}),
        ...(header.column.getIsSorted() ? tw`bg-gray-150 pr-3` : {}),
        width: header.getSize(),
        minWidth: header.getSize(),
        maxWidth: header.getSize(),
        opacity: isDragging ? 0.5 : 1,
        // eslint-disable-next-line no-nested-ternary
        ...(canDrop && isOver
          ? isAfter
            ? tw`after:([content: ''] absolute left-0 top-0 w-full h-full border-r-2 border-gray-500 -z-1)`
            : tw`after:([content: ''] absolute left-0 top-0 w-full h-full border-l-2 border-gray-500 -z-1)`
          : tw`after:([content: ''] absolute left-0 top-0 w-full h-full border-r border-gray-200 -z-1)`),
        textAlign: (header.column.columnDef as DataTableColumnDef<D>).meta?.css
          ?.textAlign,
        ...(header.column.getIsResizing() && tw`pointer-events-none`),
      }}
      onClick={header.column.getToggleSortingHandler()}
    >
      {!disableInteractions && (
        <button
          ref={dragRef}
          tw="absolute left-0.5 4xl:left-1.25 top-1/2 transform -translate-y-[53%] group-hover:opacity-100 opacity-0"
        >
          <GripVertical size="13" />
        </button>
      )}

      <Box
        ref={previewRef}
        tw="select-none items-center justify-between whitespace-nowrap overflow-hidden overflow-ellipsis"
      >
        {header.isPlaceholder ||
        (header.column.columnDef as DataTableColumnDef<D>).meta?.actions
          ? null
          : flexRender(header.column.columnDef.header, header.getContext())}

        <Absolute align="centerRight">
          {{
            asc: <SortAsc size="15" tw="text-gray-700 right-1!" />,
            desc: <SortDesc size="15" tw="text-gray-700 right-1!" />,
          }[header.column.getIsSorted() as string] ?? <></>}
        </Absolute>
      </Box>

      {!disableInteractions && (
        <Box
          tw="absolute right-0 top-0 h-full w-0.75 border-r-2 border-gray-500 cursor-col-resize hover:opacity-100 opacity-0"
          onMouseDown={header.getResizeHandler()}
          onTouchStart={header.getResizeHandler()}
          css={{
            ...(header.column.getIsResizing() ? tw`border-accent-light opacity-100` : {}),
          }}
        />
      )}
    </th>
  );
};
