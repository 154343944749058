import React, { useCallback, useState } from "react";

import { useDebouncedCallback } from "@react-hookz/web";
import { Search } from "lucide-react";

import { Flex } from "@/app/core/ui/components/Flex";

interface TableSearchProps {
  onSearch?: (term: string) => void;
}

export const TableSearch: React.FC<TableSearchProps> = ({ onSearch }) => {
  const [searchValue, setSearchValue] = useState("");

  const debounceSearch = useDebouncedCallback(
    (s: string) => {
      onSearch?.(s);
    },
    [onSearch],
    750
  );

  const handleChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(ev.target.value);
      debounceSearch(ev.target.value.trim());
    },
    [debounceSearch]
  );

  if (!onSearch) {
    return null;
  }

  return (
    <Flex tw="items-center bg-white border border-gray-300 rounded-lg gap-0.25 px-1 overflow-hidden relative min-w-[25rem] shadow-sm">
      <Search size="17" tw="relative z-10" />
      <input
        placeholder="Buscar"
        tw="pl-4 pr-1 bg-transparent outline-none absolute top-0 left-0 w-full h-full hover:bg-gray-150"
        value={searchValue}
        onChange={handleChange}
      />
    </Flex>
  );
};
