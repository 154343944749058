import React, { useCallback, useEffect, useRef, useState } from "react";

import { Popover } from "@headlessui/react";
import { Check, FileDown, MoreVertical, RefreshCw, Save } from "lucide-react";
import tw from "twin.macro";

import type { QueryExportFn } from "@/app/core/http/api-client.types";
import { PopoverItem } from "@/app/core/ui/components/popover/PopoverItem";
import { PopoverPanel } from "@/app/core/ui/components/popover/PopoverPanel";

interface TableActionsProps {
  onRefetch: () => Promise<any>;
  isRefetching: boolean;
  updateCurrentView?: () => void;
  columnIds?: string[];
  exportQueryAs?: QueryExportFn;
}

export const TableActions: React.FC<TableActionsProps> = ({
  isRefetching,
  onRefetch,
  updateCurrentView,
  exportQueryAs,
  columnIds,
}) => {
  const [hasRefetched, setHasRefetched] = useState(false);
  const hasPreviouslyRefetched = useRef(false);
  const timeoutId = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isRefetching) {
      hasPreviouslyRefetched.current = true;
      setHasRefetched(false);
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    } else if (hasPreviouslyRefetched.current) {
      hasPreviouslyRefetched.current = false;
      setHasRefetched(true);

      timeoutId.current = setTimeout(() => {
        setHasRefetched(false);
      }, 2000);
    }

    return () => (timeoutId.current ? clearTimeout(timeoutId.current) : undefined);
  }, [isRefetching]);

  const exportQuery = useCallback(
    (format: "csv" | "xlsx") => exportQueryAs?.(format, columnIds),
    [columnIds, exportQueryAs]
  );

  return (
    <>
      <Popover tw="relative z-20">
        {({ open }) => (
          <>
            <Popover.Button
              tw="bg-white h-full flex items-center justify-center border border-gray-300 rounded-lg w-[3.6rem] hover:bg-gray-150 transition-colors outline-none shadow-sm"
              css={open ? tw`bg-gray-150 shadow-none` : {}}
            >
              <MoreVertical size="18" />
            </Popover.Button>

            <PopoverPanel open={open} tw="top-full right-0">
              {(
                [
                  [
                    "Actualizar datos",
                    onRefetch,
                    <>
                      {hasRefetched ? (
                        <Check size="13" />
                      ) : (
                        <RefreshCw
                          size="13"
                          css={isRefetching ? tw`animate-rotate` : {}}
                        />
                      )}
                    </>,
                    true,
                  ],
                  [
                    "Guardar vista actual",
                    updateCurrentView,
                    <Save key="save-view" size="13" />,
                    Boolean(updateCurrentView),
                  ],
                  [
                    "Exportar tabla en CSV",
                    () => exportQuery("csv"),
                    <FileDown key="export-table-csv" size="13" />,
                    Boolean(exportQueryAs),
                  ],
                  [
                    "Exportar tabla en Excel",
                    () => exportQuery("xlsx"),
                    <FileDown key="export-table-xlsx" size="13" />,
                    Boolean(exportQueryAs),
                  ],
                ] as const
              ).map(([title, action, icon, visible], i) => (
                <React.Fragment key={`item-${i}`}>
                  {visible && (
                    <>
                      <PopoverItem
                        onClick={action}
                        tw="whitespace-nowrap min-w-[20rem] items-center text-md"
                      >
                        {icon}
                        {title}
                      </PopoverItem>
                    </>
                  )}
                </React.Fragment>
              ))}
            </PopoverPanel>
          </>
        )}
      </Popover>
    </>
  );
};
