import React from "react";

import { Popover } from "@headlessui/react";
import type { RowData, Table } from "@tanstack/react-table";
import { AnimatePresence, motion } from "framer-motion";
import { Columns } from "lucide-react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import tw from "twin.macro";

import { Checkbox } from "@/app/core/forms/components/Checkbox";
import { Flex } from "@/app/core/ui/components/Flex";
import { Text } from "@/app/core/ui/components/Text";

interface ColumnFilterProps<D> {
  table: Table<D>;
}

export const ColumnFilter = <D extends RowData>({
  table,
}: ColumnFilterProps<D>): JSX.Element => {
  return (
    <Popover tw="relative z-20">
      {({ open }) => (
        <>
          <Popover.Button
            tw="bg-white h-full flex items-center justify-center border border-gray-300 px-1 rounded-lg gap-0.75 outline-none hover:bg-gray-150 transition-colors shadow-sm"
            css={open ? tw`shadow-none bg-gray-150` : {}}
          >
            <Columns size="18" />
            <Text size="md" weight="medium">
              Columnas
            </Text>
          </Popover.Button>

          <DndProvider backend={HTML5Backend}>
            <AnimatePresence>
              {open && (
                <motion.div
                  layout
                  initial={{ opacity: 0, y: -10, scale: 1 }}
                  animate={{ opacity: 1, y: 0, scale: 1 }}
                  exit={{ opacity: 0, y: -10, scale: 1 }}
                  transition={{
                    type: "spring",
                    bounce: 0.4,
                    duration: 0.4,
                  }}
                >
                  <Popover.Panel
                    static
                    tw="absolute right-0 top-full min-w-[30rem] bg-white rounded-lg bg-white shadow-lg
                    border border-gray-300 z-10 py-1 max-h-[40rem] overflow-y-auto"
                  >
                    {table.getAllColumns().map((column) => (
                      <Flex key={column.id} tw="py-0.75 px-1.5 items-center gap-3">
                        <Checkbox
                          id={column.id}
                          checked={column.getIsVisible()}
                          onChange={column.getToggleVisibilityHandler()}
                        >
                          {column.columnDef.header as unknown as React.ReactElement}
                        </Checkbox>
                      </Flex>
                    ))}
                  </Popover.Panel>
                </motion.div>
              )}
            </AnimatePresence>
          </DndProvider>
        </>
      )}
    </Popover>
  );
};
