import React from "react";

import { Popover } from "@headlessui/react";
import type { Cell } from "@tanstack/react-table";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import { MoreVertical } from "lucide-react";
import tw from "twin.macro";

import type { DataTableColumnDef } from "@/app/core/datatable/datatable.types";
import { Text } from "@/app/core/ui/components/Text";

interface ActionsCellProps {
  cell: Cell<any, unknown>;
}

export const ActionsCell: React.FC<ActionsCellProps> = ({ cell }) => {
  return (
    <td
      tw="relative w-2 whitespace-nowrap"
      style={{ width: "5%", maxWidth: "5%", minWidth: "5%" }}
    >
      <Popover tw="absolute h-full w-full top-0 right-0 z-10 w-5">
        {({ open }) => (
          <>
            <Popover.Button
              tw="w-full h-full hover:bg-gray-100 transition-colors outline-none"
              css={open ? tw`bg-gray-200 hover:bg-gray-200` : {}}
            >
              <MoreVertical size="18" tw="mx-auto text-gray-700" strokeWidth={1.3} />
            </Popover.Button>

            <LayoutGroup>
              <AnimatePresence mode="wait" initial={false}>
                {open && (
                  <motion.div
                    tw="absolute right-full top-1/2"
                    layout
                    initial={{
                      opacity: 0,
                      x: 10,
                      y: -10,
                      scale: 1,
                    }}
                    animate={{
                      opacity: 1,
                      x: 0,
                      y: 0,
                      scale: 1,
                    }}
                    exit={{
                      opacity: 0,
                      x: 10,
                      y: -10,
                      scale: 1,
                    }}
                    transition={{
                      type: "spring",
                      bounce: 0.4,
                      duration: 0.4,
                    }}
                  >
                    <Popover.Panel
                      static
                      tw="bg-white rounded-lg bg-white shadow-lg border border-gray-300 z-10 min-w-[15rem] p-0.5"
                    >
                      {(cell.column.columnDef as DataTableColumnDef<any>).meta
                        ?.actions?.(cell)
                        .map((action, i) => (
                          <button
                            key={"action" + cell.id + i}
                            tw="w-full flex items-center px-1 py-0.75 gap-1 hover:bg-gray-100 rounded-lg text-[1.2rem] disabled:(opacity-50 cursor-not-allowed [filter:grayscale(1)])"
                            onClick={() => action.action()}
                            disabled={action.disabled}
                          >
                            {action.icon}
                            {typeof action.title === "string" ? (
                              <Text>{action.title}</Text>
                            ) : (
                              action.title
                            )}
                          </button>
                        ))}
                    </Popover.Panel>
                  </motion.div>
                )}
              </AnimatePresence>
            </LayoutGroup>
          </>
        )}
      </Popover>
    </td>
  );
};
