/* eslint-disable comma-spacing */
import React, { useCallback, useMemo, useRef, useState } from "react";

import { useMountEffect } from "@react-hookz/web";
import type { UseQueryResult } from "@tanstack/react-query";
import type { RowData, TableOptions } from "@tanstack/react-table";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { FileDown } from "lucide-react";
import { useVirtual } from "react-virtual";
import type { VirtualItem } from "react-virtual";

import { ColumnFilter } from "@/app/core/datatable/components/ColumnFilter";
import { TableActions } from "@/app/core/datatable/components/TableActions";
import { TableSearch } from "@/app/core/datatable/components/TableSearch";
import type {
  DataTableColumnDef,
  InitialColumnState,
} from "@/app/core/datatable/datatable.types";
import { useTableColumnState } from "@/app/core/datatable/filters/hooks/useTableColumnState";
import { TableFilters } from "@/app/core/datatable/filters/TableFilters";
import { Table } from "@/app/core/datatable/Table";
import type { UseInfiniteQueryResultWithMeta } from "@/app/core/http/api-client.types";
import { Box } from "@/app/core/ui/components/Box";
import { Flex } from "@/app/core/ui/components/Flex";
import { Loader } from "@/app/core/ui/components/Loader";
import type {
  ClientFacets,
  Paginated,
  TableFilters as TableFiltersType,
} from "@obd/common";

interface DataTableProps<D extends RowData>
  extends Omit<TableOptions<D>, "getCoreRowModel" | "columns" | "data" | "initialState"> {
  data: UseQueryResult<D[]> | UseInfiniteQueryResultWithMeta<Paginated<D[], any>>;
  enableColumnVisibility?: boolean;
  onSearch?: (term: string) => void;
  columns: DataTableColumnDef<D>[];
  hideHeader?: boolean;
  smaller?: boolean;
  filters?: TableFiltersType<D> & {
    activeFacets?: ClientFacets;
    setActiveFacets?: React.Dispatch<React.SetStateAction<ClientFacets>>;
  };
  initialState?: { columns?: Partial<InitialColumnState> };
  updateCurrentView?: () => void;
  height?: number;
  minHeight?: string;
  disableInteractions?: boolean;
  initialSearch?: string;
  totalCount?: number;
  renderInnerRow?: (d: any, colSpan: number, virtual: VirtualItem) => any;
}

export const DataTable = <D extends RowData>({
  data: queryData,
  onSearch,
  hideHeader = false,
  smaller = false,
  filters,
  initialState,
  updateCurrentView,
  height,
  minHeight = "50rem",
  disableInteractions = false,
  totalCount,
  renderInnerRow,
  ...options
}: DataTableProps<D>) => {
  // Set the table height to fit the current window height

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tableContainerHeight =
    height ??
    (tableContainerRef.current &&
      window.scrollY + tableContainerRef.current.getBoundingClientRect().top);

  const columns = useMemo(() => [...options.columns], [options.columns]);
  const initialColumnState = initialState?.columns;
  const {
    columnVisibility,
    setColumnVisibility,
    columnSizing,
    setColumnSizing,
    columnOrder,
    setColumnOrder,
    columnSorting,
    setColumnSorting,
  } = useTableColumnState(columns, initialColumnState);

  const columnIds = useMemo(
    () =>
      columns
        .map((c) => c.id ?? "")
        .filter(
          (id) => !!id && (!(id in columnVisibility) || columnVisibility[id] === true)
        ),
    [columnVisibility, columns]
  );

  // Paginated data needs to be flattened into a single
  // array

  const data = useMemo<D[]>(() => {
    if (!queryData.data) {
      return [];
    }

    if ("hasNextPage" in queryData) {
      return queryData.data.pages
        .flat()
        .map((p) => p.items)
        .flat();
    } else {
      return queryData.data as D[];
    }
  }, [queryData]);

  // Called on scroll and possibly on mount to fetch more data as the user scrolls
  // and reaches bottom of table

  const fetchNextPageOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (!containerRefElement || !("hasNextPage" in queryData)) {
        return;
      }

      const { scrollHeight, scrollTop, clientHeight } = containerRefElement;

      const totalFetched = data.length;
      const totalRowCount =
        "hasNextPage" in queryData
          ? queryData.data?.pages?.[0]?.meta?.total ?? totalCount ?? 0
          : 0;

      // Once the user has scrolled within 300px of the bottom of the table,
      // fetch more data if there is any

      if (
        ((scrollTop > 0 && scrollHeight - scrollTop - clientHeight < 300) ||
          scrollHeight === clientHeight) &&
        !queryData.isFetching &&
        totalFetched < totalRowCount
      ) {
        queryData.fetchNextPage();
      }
    },
    [data.length, queryData, totalCount]
  );

  // A check on mount and after a fetch to see if the table is already scrolled to the bottom
  // and immediately needs to fetch more data

  useMountEffect(() => {
    fetchNextPageOnBottomReached(tableContainerRef.current);
  });

  // Create the table

  const table = useReactTable({
    ...options,
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: "onEnd",
    onColumnVisibilityChange: disableInteractions ? undefined : setColumnVisibility,
    onColumnOrderChange: disableInteractions ? undefined : setColumnOrder,
    onColumnSizingChange: disableInteractions ? undefined : setColumnSizing,
    onSortingChange: disableInteractions ? undefined : setColumnSorting,
    defaultColumn: {
      enableSorting: false,
    },
    state: disableInteractions
      ? undefined
      : {
          columnOrder,
          columnVisibility,
          columnSizing,
          sorting: columnSorting,
        },
  });

  const [activeInnerId, setActiveInnerId] = useState<string>();

  const tableRows = table.getRowModel().rows;
  const rows = useMemo(() => {
    const idMatch = tableRows.findIndex((r) => r.id === activeInnerId);
    if (idMatch !== -1) {
      return [
        ...tableRows.slice(0, idMatch + 1),
        null as any,
        ...tableRows.slice(idMatch + 1, tableRows.length),
      ];
    }

    return tableRows;
  }, [activeInnerId, tableRows]);

  // Virtualizing is optional, but might be necessary if we are going to
  // potentially have hundreds or thousands of rows

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 20,
  });

  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;
  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  return (
    <Flex tw="flex-col w-full bg-white border border-gray-300 rounded-lg overflow-y-hidden overflow-x-auto">
      {!hideHeader && (
        <Flex tw="py-1 px-1 xl:px-3 justify-between gap-1 h-[5.7rem] bg-gray-100 border-b border-gray-300 z-10">
          <Flex tw="h-full items-center">
            {queryData.isInitialLoading || queryData.isRefetching ? (
              <Loader
                tw="text-gray-600"
                svgProps={{
                  width: 17,
                  style: {
                    animationDuration: "0.5s",
                  },
                }}
              />
            ) : null}
          </Flex>

          <Flex tw="gap-1 h-full">
            <TableSearch onSearch={onSearch} />
            {filters?.facets &&
              filters.facets.length > 0 &&
              filters.activeFacets &&
              filters.setActiveFacets && (
                <TableFilters
                  filters={filters.facets}
                  activeFilters={filters.activeFacets}
                  setActiveFilters={filters.setActiveFacets}
                />
              )}
            <ColumnFilter table={table} />
            <TableActions
              onRefetch={queryData.refetch}
              isRefetching={queryData.isRefetching}
              updateCurrentView={updateCurrentView}
              columnIds={columnIds}
              exportQueryAs={
                "meta" in queryData ? queryData.meta?.exportQueryAs : undefined
              }
            />
          </Flex>
        </Flex>
      )}

      {hideHeader && "meta" in queryData && queryData.meta?.exportQueryAs && (
        <Flex tw="py-0.5 px-1.5 bg-gray-100 border-b border-gray-200 justify-end">
          <button
            tw="bg-white h-full flex items-center justify-center border border-gray-300 px-1 py-0.25 rounded-lg gap-0.5 outline-none hover:bg-gray-150 transition-colors shadow-sm text-sm font-medium"
            onClick={() => queryData.meta.exportQueryAs?.("xlsx", columnIds)}
          >
            <FileDown size="13" />
            Exportar
          </button>
        </Flex>
      )}

      <Box
        tw="overflow-auto"
        ref={tableContainerRef}
        onScroll={(e) => fetchNextPageOnBottomReached(e.target as HTMLDivElement)}
        css={{
          minHeight,
          maxHeight: height
            ? height
            : tableContainerHeight
            ? `calc(-${tableContainerHeight}px + 100vh)`
            : undefined,
        }}
      >
        <Table
          table={table}
          rows={rows}
          smaller={smaller}
          virtualRows={virtualRows}
          paddingBottom={paddingBottom}
          paddingTop={paddingTop}
          disableInteractions={disableInteractions}
          renderInnerRow={renderInnerRow}
          activeInnerId={activeInnerId}
          setActiveInnerId={setActiveInnerId}
        />
      </Box>
    </Flex>
  );
};
