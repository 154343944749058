import React from "react";

import type { Table as ReactTable, Row, RowData } from "@tanstack/react-table";
import { flexRender } from "@tanstack/react-table";
import { PlusIcon } from "lucide-react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import type { VirtualItem } from "react-virtual";
import tw from "twin.macro";

import { ActionsCell } from "@/app/core/datatable/components/ActionsCell";
import { ColumnHeader } from "@/app/core/datatable/components/ColumnHeader";
import type { DataTableColumnDef } from "@/app/core/datatable/datatable.types";
import { Flex } from "@/app/core/ui/components/Flex";
import { Text } from "@/app/core/ui/components/Text";

interface TableProps<D extends RowData> {
  table: ReactTable<D>;
  rows: Row<D>[];
  smaller: boolean;
  virtualRows: VirtualItem[];
  paddingTop?: number;
  paddingBottom?: number;
  autoSize?: boolean;
  disableInteractions?: boolean;
  renderInnerRow?: (d: any, colSpan: number, virtual: VirtualItem) => JSX.Element;
  activeInnerId: string | undefined;
  setActiveInnerId: (d: string | undefined) => void;
}

export const Table = <D extends RowData>({
  table,
  rows,
  smaller,
  virtualRows,
  paddingBottom = 0,
  paddingTop = 0,
  disableInteractions = false,
  renderInnerRow,
  activeInnerId,
  setActiveInnerId,
}: TableProps<D>): JSX.Element => {
  return (
    <DndProvider backend={HTML5Backend}>
      <table
        width="99.9%"
        tw="text-gray-900"
        css={{
          ...(smaller ? tw`text-[1.2rem]` : tw`text-md`),
        }}
      >
        <thead
          tw="sticky top-0 bg-gray-100 z-[9] transition-all
      after:([content: ''] absolute left-0 bottom-0 w-full border-b border-gray-300)"
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {renderInnerRow && (
                <th style={{ width: 45, minWidth: 45, maxWidth: 45 }}></th>
              )}
              {headerGroup.headers.map((header) => (
                <ColumnHeader<D>
                  header={header}
                  table={table}
                  key={header.id}
                  disableInteractions={disableInteractions}
                />
              ))}
            </tr>
          ))}
        </thead>
        <tbody tw="divide-y divide-gray-200">
          {paddingTop > 0 && (
            <tr>
              <td style={{ height: `${paddingTop}px` }} />
            </tr>
          )}

          {!rows.length ? (
            <tr>
              <Text
                colSpan={table.getHeaderGroups()[0]?.headers.length ?? 1}
                as="td"
                align="center"
                color="gray-600"
                size="md"
                tw="py-2"
              >
                Sin resultados
              </Text>
            </tr>
          ) : null}

          {virtualRows.map((virtualRow) => {
            const row = rows[virtualRow.index];

            return (
              <React.Fragment key={row?.id ?? "inner"}>
                {row ? (
                  <tr
                    ref={virtualRow.measureRef}
                    tw="hover:bg-gray-50 transition-colors"
                    css={{
                      ...(renderInnerRow && tw`cursor-pointer`),
                      minHeight: virtualRow.size,
                      ...(activeInnerId !== undefined &&
                        (activeInnerId !== row.id ? tw`opacity-20` : tw`shadow-xl`)),
                    }}
                    onClick={
                      renderInnerRow &&
                      (() => {
                        if (activeInnerId) {
                          setActiveInnerId(undefined);
                        } else {
                          setActiveInnerId(row.id);
                        }
                      })
                    }
                  >
                    {renderInnerRow && (
                      <td
                        tw="relative border-r border-gray-150"
                        style={{ width: 45, minWidth: 45, maxWidth: 45 }}
                      >
                        <Flex center tw="w-full">
                          <PlusIcon
                            size="14"
                            tw="rotate-0 transition-transform"
                            css={{ ...(activeInnerId === row.id && tw`rotate-45`) }}
                          />
                        </Flex>
                      </td>
                    )}

                    {row.getVisibleCells().map((cell) => (
                      <React.Fragment key={cell.id}>
                        {(cell.column.columnDef as DataTableColumnDef<D>).meta
                          ?.actions ? (
                          <ActionsCell cell={cell} />
                        ) : (
                          <td
                            tw="relative whitespace-nowrap overflow-hidden overflow-ellipsis
                        border-r border-gray-150 py-1 px-2 4xl:px-3"
                            css={{
                              minWidth: cell.column.getSize(),
                              maxWidth: cell.column.getSize(),
                              width: cell.column.getSize(),
                              ...(cell.column.columnDef as DataTableColumnDef<D>).meta
                                ?.css,
                              ...(cell.column.getIsResizing() && tw`border-gray-500`),
                            }}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        )}
                      </React.Fragment>
                    ))}
                  </tr>
                ) : (
                  renderInnerRow &&
                  activeInnerId &&
                  renderInnerRow(
                    table.getRow(activeInnerId).getValue("inner"),
                    table.getFlatHeaders().length + 1,
                    virtualRow
                  )
                )}
              </React.Fragment>
            );
          })}

          {paddingBottom > 0 && (
            <tr>
              <td style={{ height: `${paddingBottom}px` }} />
            </tr>
          )}
        </tbody>
      </table>
    </DndProvider>
  );
};
