import React from "react";

import tw from "twin.macro";

import { styled } from "@/app/core/styles/stitches.config";

const CheckboxElement = styled("input", {
  ...tw`absolute invisible`,
  "&:checked + label span:first-child": {
    background: "#0077ff",
    borderColor: "#0077ff",
    animation: "zoom-in-out 0.3s ease",
  },
  "&:checked + label span:first-child svg": {
    strokeDashoffset: 0,
  },
});

const Label = styled("label", {
  ...tw`select-none cursor-pointer transition-all overflow-hidden flex items-center`,
  "&:not(:last-child)": {
    marginRight: "6px",
  },
  "> span": {
    verticalAlign: "middle",
    transform: "translate3d(0, 0, 0)",
  },
  "> span:first-child": {
    position: "relative",
    flex: "0 0 15px",
    width: "15px",
    height: "15px",
    borderRadius: "3px",
    transform: "scale(1)",
    border: "1px solid #cccfdb",
    transition: "all 0.12s ease",
  },
  "> span:first-child svg": {
    position: "absolute",
    top: "3px",
    left: "2px",
    fill: "none",
    stroke: "#fff",
    strokeDasharray: "15px",
    strokeDashoffset: "15px",
    transition: "all 0.12s ease",
    transform: "translate3d(0, 0, 0)",
  },
  "> span:last-child": {
    paddingLeft: "10px",
  },
  "&:hover span:first-child": {
    ...tw`border-gray-300`,
  },
});

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  id,
  children,
  ...props
}) => {
  return (
    <>
      <CheckboxElement
        type="checkbox"
        id={`custom_checkbox_${id}`}
        {...props}
      />
      <Label htmlFor={`custom_checkbox_${id}`}>
        <span>
          <svg width="9px" height="7px" viewBox="0 0 12 10">
            <polyline
              points="1.5 6 4.5 9 10.5 1"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </svg>
        </span>

        <span tw="-mt-[0.1rem]">{children}</span>
      </Label>
    </>
  );
};
