import { useEffect, useMemo, useState } from "react";

import type {
  ColumnOrderState,
  ColumnSizingState,
  OnChangeFn,
  SortingState,
} from "@tanstack/react-table";

import type {
  DataTableColumnDef,
  InitialColumnState,
} from "@/app/core/datatable/datatable.types";

export const useTableColumnState = <D>(
  columns: DataTableColumnDef<D>[],
  initial?: Partial<InitialColumnState>
) => {
  const [columnVisibility, _setColumnVisibility] = useState<Record<string, boolean>>({});
  const [columnSizing, _setColumnSizing] = useState<ColumnSizingState>({});
  const [columnSorting, _setColumnSorting] = useState<SortingState>([]);
  const [columnOrder, _setColumnOrder] = useState<ColumnOrderState>(
    columns.map((column) => column.id as string)
  );

  const setColumnVisibility = useMemo<OnChangeFn<Record<string, boolean>>>(
    () => initial?.setColumnVisibility ?? _setColumnVisibility,
    [initial?.setColumnVisibility]
  );

  const setColumnSizing = useMemo<OnChangeFn<ColumnSizingState>>(
    () => initial?.setColumnSizing ?? _setColumnSizing,
    [initial?.setColumnSizing]
  );

  const setColumnOrder = useMemo<OnChangeFn<ColumnOrderState>>(
    () => initial?.setColumnOrder ?? _setColumnOrder,
    [initial?.setColumnOrder]
  );

  const setColumnSorting = useMemo<OnChangeFn<SortingState>>(
    () => initial?.setColumnSorting ?? _setColumnSorting,
    [initial?.setColumnSorting]
  );

  useEffect(() => {
    if (
      initial?.isLoading ||
      (initial?.columnVisibility && Object.keys(initial.columnVisibility).length) ||
      !columns.filter((c) => c.hide).length
    ) {
      return;
    }

    const v: Record<string, boolean> = {};
    for (const c of columns.filter((cl) => cl.hide)) {
      if (c.id) {
        v[c.id] = false;
      }
    }

    setColumnVisibility(v);
  }, [columns, initial?.columnVisibility, initial?.isLoading, setColumnVisibility]);

  useEffect(() => {
    if (
      !initial?.isLoading &&
      initial?.setColumnOrder &&
      initial.columnOrder &&
      !initial.columnOrder.length
    ) {
      setColumnOrder(columns.map((column) => column.id as string));
    }
  }, [
    columns,
    initial?.columnOrder,
    initial?.isLoading,
    initial?.setColumnOrder,
    setColumnOrder,
  ]);

  return {
    columnVisibility: initial?.columnVisibility ?? columnVisibility,
    setColumnVisibility,
    columnOrder: initial?.columnOrder ?? columnOrder,
    setColumnOrder,
    columnSizing: initial?.columnSizing ?? columnSizing,
    setColumnSizing,
    columnSorting: initial?.columnSorting ?? columnSorting,
    setColumnSorting,
  };
};
